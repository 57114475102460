import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { Modal } from 'react-bootstrap'
import axios from 'axios'

// const queryString = require('query-string')
import { orderStates } from '../data/constants'

export class ModalOrderDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            state: false,
            orderID: null,
            paymentResult: null,
            order: false,
            show: true
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    componentDidMount = async () => {
        await this.setStateAsync({ orderID: this.props.data.id })
        this.getOrder(this.props.id)
        this.setState({ show: true })
    }

    getOrder = (id) => {
        axios.get('https://api.kavaprolekare.cz/order', { params: { _id: this.state.orderID } })
            .then(response => {
                this.setState({ order: response.data })
                // console.log(this.state.order)
            })
            .catch(error => {
                //alert(error)
            })
    }

    payOrder = () => {
        window._gopay.checkout({ gatewayUrl: this.state.order.orderHeader.payment.url, inline: true }, (checkoutResult) => {
            this.checkPayment(this.state.paymentId)
        })
    }

    fun() {
        this.props.url && this.props.history.push("/");
        this.toggleModal()
    }

    toggleModal = () => this.state.show ? this.setState({ show: false }) : this.setState({ show: true })

    render() {
        return (
            <Modal show={this.state.show} onHide={this.fun.bind(this)} size="lg">
                <Modal.Header closeButton>
                    {this.state.order && (<>Objednávka č. {this.state.order.orderHeader.number}</>)}
                    {!this.state.order && (<>Objednávka nenalezena.</>)}
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.order && (
                            <>
                                <p><b>Stav objednávky: {orderStates[this.state.order.state]}</b></p>
                                <hr />
                                <h4>Doručovací adresa (dopravce PPL):</h4>
                                <p>{this.state.order.orderHeader.partnerIdentity.name}</p>
                                <p>{this.state.order.orderHeader.partnerIdentity.deliveryAddress.street}</p>
                                <p>{this.state.order.orderHeader.partnerIdentity.deliveryAddress.zip} {this.state.order.orderHeader.partnerIdentity.deliveryAddress.city}</p>
                                <hr />
                                <h4>Položky:</h4>
                                {this.state.order.orderDetailType.orderItems.map(it => (
                                    <p>{it.quantity} x {it.text}</p>
                                ))}
                            </>
                        )}
                    {this.state.state === "CREATED" && (
                        <Button color="primary" variant="contained" type="submit" onClick={this.payOrder}>Zaplatit</Button>
                    )}
                    {this.state.paymentResult}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.url && (
                        <Button variant="secondary" onClick={this.fun.bind(this)}>Zavřít</Button>
                    )}
                    {!this.props.url && (
                        <Button variant="secondary" onClick={this.fun.bind(this)}>Zavřít</Button>
                    )}


                </Modal.Footer>
            </Modal >
        )
    }
}

export default withRouter(ModalOrderDetail)