export const partners = {
    shops: [
        {
            company: "DP Coffee",
            url: "https://eshop.dpcoffee.cz/",
            name: "www.dpcoffee.cz",
            logo: "/icon.png",
            width: 90,
            roundedCircle: true
        },
        {
            company: "Pilulka",
            url: "https://www.pilulka.cz/hledej?c%5B0%5D=7-363&q=kava",
            name: "www.pilulka.cz",
            logo: "/pilulka-logo.svg",
            width: 180
        }
    ],
    bussiness: [
        {
            company: "Panorama Golf Resort",
            url: "https://www.panoramagolf.cz/",
            name: "www.panoramagolf.cz",
            logo: "/partners/panoramaGolf.jpg",
            width: 135
        },
        {
            company: "FV Plast",
            url: "https://www.fv-plast.cz",
            name: "www.fv-plast.cz",
            logo: "/partners/fv-plast.gif",
            width: 135
        },
        {
            company: "Bravo",
            url: "https://www.isolitbravo.cz",
            name: "www.isolitbravo.cz",
            logo: "/partners/bravo.png",
            width: 135
        },
        {
            company: "Energii lékařům",
            url: "https://www.energiilekarum.cz/",
            name: "www.energiilekarum.cz",
            logo: "/partners/energii-lekarum.png",
            width: 90
        },
    ],
    technical: [
        {
            company: "IT Profik",
            url: "https://www.itprofik.cz",
            name: "www.itprofik.cz",
            logo: "/partners/itProfik.gif",
            width: 135
        },
    ]
}