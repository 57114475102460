import React from 'react'

import { Container, Jumbotron } from 'react-bootstrap'

function AboutUs() {
    return (
        <>
            <Container id="project" style={{ backgroundColor: 'white' }}>
                <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                    <h1 className="header">Milion šálků kávy pro lékaře</h1>
                    <p>V dnešní nelehké době chceme i my pomoci a poděkovat lékařům za jejich nasazení během pandemie.</p>
                    <p>Naším cílem je společně s Vámi a našimi partnery dodat lékařům 1 000 000 šálků kávy a zpříjemnit jim tak jejich nelehkou práci.</p>
                    <p>Do zapojených zdravotnických zařízení dodáme moderní nové kávovary, kávu, poskytneme pravidelnou údržbu a servis kávovarů.</p>
                    <p>A to po dobu nejméně 3 měsíců.</p>
                    <p>Projekt jsme již odstartovali na několika místech po celé ČR a stále instalujeme další nové kávovary na další covidová vytížená místa.</p>
                    <p>Při výběru partnerských nemocnic se zaměřujeme na emergency a covidová oddělení nemocnic, ARO, JIP a záchranné služby.</p>
                </Jumbotron>
            </Container>

        </>
    )
}

export default AboutUs
