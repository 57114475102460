import './App.css';

import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Container from '@material-ui/core/Container'
import { Modal } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

import Navigation from './components/Navigation'
import MainPage from './components/MainPage'
import AboutUs from './components/AboutUs'
import Project from './components/Project'
import Questions from './components/Questions'
import Partners from './components/Partners'
import Doctors from './components/Doctors'
import Gallery from './components/Gallery'
import Bottom from './components/Bottom'

import ModalPaymentResult from './components/ModalPaymentResult'
import ModalOrderDetail from './components/ModalOrderDetail'

const queryString = require('query-string')
const parsed = queryString.parse(window.location.search);

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      login: false,
      name: '',
      password: ''
    }
  }

  handleName = (val) => {
    this.setState({ name: val.target.value })
    // console.log(val)
  }

  handlePassword = (val) => {
    this.setState({ password: val.target.value })
  }

  handleLogin = () => {
    if (this.state.name === 'kbanka' && this.state.password === 'komercni')
      this.setState({ login: false })
  }



  render() {
    return (
      <>
        <Router>
          <Container maxWidth="lg">
            <Navigation />
            <MainPage />
            <Project />
            <Questions />
            <Gallery />
            <Partners />
            <AboutUs />
            <Doctors />
            <Bottom />
            <Switch>
              <Route path="/goPay/" children={<ModalPaymentResult payment={{ id: parsed.id, gateway: 'GOPAY' }} url={true} />} />
              <Route path="/order/" children={<ModalOrderDetail data={{ id: parsed.id }} url={true} />} />
            </Switch>
          </Container>
        </Router>

        <img src="/logo/logo_original.png" alt="Kava pro doktora" height='20%' style={{ right: 0, bottom: 0, position: 'fixed', marginBottom: '1%', marginRight: '1%' }}></img>

        <Modal show={this.state.login} onHide={this.handleLogin}>
          <Modal.Header>Přihlášení</Modal.Header>
          <Modal.Body>
            <div style={{ float: 'left' }}>
              <TextField label="jméno" onChange={this.handleName} /><br />
              <TextField label="heslo" type="password" onChange={this.handlePassword} /><br />
            </div>
            <div style={{ float: 'right' }}>
              <img src="/logo/logo_original.png" alt="Kava pro doktora" height='100'></img>
            </div>
            <div style={{ clear: 'both' }}></div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleLogin}>Vstoupit</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
export default App;
