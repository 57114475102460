import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup'
import axios from 'axios'


export class DoctorsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: true,
            initialValues: {
                name: '',
                role: '',
                hospital: '',
                city: '',
                email: '',
                phoneNumber: '',
            }
        }
    }

    handleClose = () => { this.props.close() }

    submitForm = (values, props) => {
        const data = {
            name: values.hospital,
            contact: {
                name: values.name,
                role: values.role,
                phoneNumber: values.phoneNumber,
                email: values.email
            },
            address: {
                city: values.city
            }
        }

        axios.post('https://api.kavaprolekare.cz/hospital', data)
            .then(response => {
                this.props.next({ passed: true })
            })
            .catch(error => {
                console.log('Chyba:\n', error)
                this.props.next({ passed: false })
            })

    }

    render() {
        const validationSchema = Yup.object().shape({
            name: Yup.string().min(3, "Minimální délka jména 3 znaky").required("Zadejte Vaše jméno"),
            role: Yup.string().min(3, "Minimální délka jména 3 znaky").required("Zadejte Vaší pracovní pozici"),
            hospital: Yup.string().min(3, "Minimální délka jména 3 znaky").required("Zadejte název Vaší nemocnice"),
            city: Yup.string().min(2, "Minimální délka jména 2 znaky").required("Zadejte název města, kde se nemocnice nachází"),
            email: Yup.string().email("Zadejte platný email").required("Zadejte Váš email"),
            phoneNumber: Yup.number().typeError("Zadejte platné telefonní číslo").required("Zadejte Vaše telefonní číslo")
        })

        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Registrace nemocnice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        onSubmit={this.submitForm}
                    >
                        {(props) => (
                            <Form>
                                <Field as={TextField}
                                    fullWidth
                                    name="name"
                                    label="Jméno"
                                    helperText={<ErrorMessage name='name' />}
                                    error={props.errors.name && props.touched.name}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="role"
                                    label="Pracovní pozice"
                                    helperText={<ErrorMessage name='role' />}
                                    error={props.errors.role && props.touched.role}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="hospital"
                                    label="Nemocnice"
                                    helperText={<ErrorMessage name='hospital' />}
                                    error={props.errors.hospital && props.touched.hospital}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="city"
                                    label="Město"
                                    helperText={<ErrorMessage name='city' />}
                                    error={props.errors.city && props.touched.city}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    helperText={<ErrorMessage name='email' />}
                                    error={props.errors.email && props.touched.email}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="phoneNumber"
                                    label="Telefon"
                                    helperText={<ErrorMessage name='phoneNumber' />}
                                    error={props.errors.phoneNumber && props.touched.phoneNumber}
                                    required
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <Button onClick={this.handleClose}>
                                        Zrušit
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Registrovat
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default DoctorsForm
