import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css';

import "react-image-gallery/styles/css/image-gallery.css";

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#5CB8B2',
      main: '#5CB8B2',
      dark: '#5CB8B2',
      contrastText: '#fff',
    },
    secondary: {
      light: '#B9AB97',
      main: '#B9AB97',
      dark: '#B9AB97',
      contrastText: '#000',
    },
  }
})



ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#eee' }}>
        <App />
      </div>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
