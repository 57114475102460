import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

import { carouselGallery } from '../data/carousel-gallery'

export class CarouselCont extends Component {
    render() {
        return (
            <Carousel fade controls={false} indicators={false}>
                {carouselGallery.map((item, index) => (
                    <Carousel.Item key={'carousel-' + index.toString()}>
                        <img
                            className="d-block w-100 h-20"
                            src={process.env.PUBLIC_URL + item.img}
                            alt={item.alt}
                        />
                        {/* <Carousel.Caption>
                            <h1 style={{ fontSize: '8vmin' }}>{item.caption}</h1>
                        </Carousel.Caption> */}
                    </Carousel.Item>
                ))}
            </Carousel>
        )
    }
}

export default CarouselCont



