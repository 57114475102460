// formik:
// https://www.youtube.com/watch?v=IwbgS2LZugw

import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
// import MuiAlert from '@material-ui/lab/Alert';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'

import axios from 'axios'

import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';

//import BtcPay from './BtcPay'

// import GoPay from './GoPay'


// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export class ModalOrderForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            paymentDone: false,
            paymentID: null,
            test: 'asdf',
            // btcPay: false,
            // btcPayUrl: '',

            hospital: null,
            hospitalsList: [],
            invoice: false,

            initialValues: {
                name: '',
                email: '',
                phoneNumber: '',
                hospital: 0,
                message: '',
                conditions: false
            }
        }
    }

    componentDidMount = () => {
        axios.get('https://api.kavaprolekare.cz/hospitals')
            .then(response => {
                this.setState({ hospitalsList: response.data })
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ paymentError: false });
    };

    // toggleBtcPay = () => this.state.btcPay ? this.setState({ btcPay: false }) : this.setState({ btcPay: true })

    submitForm = (values, props) => {
        const data = {
            paymentDone: false,
            paymentID: null,
            order: this.props.orderProduct,
            customer: values,
            gateway: 'GoPay'
        }

        // axios.post('http://192.168.1.100:3443/btcpay', data)
        //     .then(response => {
        //         alert(JSON.stringify(response.data.invoice))
        //         props.resetForm()
        //         this.setState({ btcPayUrl: response.data.invoice })
        //         this.setState({ btcPay: true })
        //     })
        // this.setState({ btcPayUrl: response.data.invoice })
        // this.setState({ btcPay: true })


        axios.post('https://api.kavaprolekare.cz/coffeeOrder', data)
            .then(response => {
                window._gopay.checkout({ gatewayUrl: response.data.payment.url, inline: true }, (checkoutResult) => {
                    //objekt checkoutResult, ktery obsahuje vlastnost id a url (return-url)
                    this.setState({ paymentID: checkoutResult.id })
                    //Zobrazime vysedek platby
                    this.props.next({ payment: { id: checkoutResult.id, gateway: 'GOPAY' } })
                })
            })
            .catch(error => {
                // console.log(error)
                alert("Nepodařilo se nám spojit s platební bránou.")
            })
    }

    toggleInvoice = () => {
        this.state.invoice ? this.setState({ invoice: false }) : this.setState({ invoice: true })
    }

    render() {
        const order = this.props.orderProduct

        console.log(order)

        const validationSchema = order.gift ? Yup.object().shape({
            name: Yup.string().min(3, "Minimální délka jména 3 znaky").required("Zadejte Vaše jméno"),
            email: Yup.string().email("Zadejte platný email").required("Zadejte Váš email"),
            phoneNumber: Yup.number().typeError("Zadejte platné telefonní číslo").required("Zadejte Vaše telefonní číslo"),
            conditions: Yup.boolean().oneOf([true], "Musíte souhlasit s obchodními podmínkami.").required("Musíte souhlasit s obchodními podmínkami."),
            street: Yup.string().required("Zadejte ulici"),
            zip: Yup.string().required("Zadejte psć"),
            city: Yup.string().required("Zadejte město"),
        }) : Yup.object().shape({
            name: Yup.string().min(3, "Minimální délka jména 3 znaky").required("Zadejte Vaše jméno"),
            email: Yup.string().email("Zadejte platný email").required("Zadejte Váš email"),
            phoneNumber: Yup.number().typeError("Zadejte platné telefonní číslo").required("Zadejte Vaše telefonní číslo"),
            conditions: Yup.boolean().oneOf([true], "Musíte souhlasit s obchodními podmínkami.").required("Musíte souhlasit s obchodními podmínkami."),
        })

        return (
            <Modal show={true} onHide={this.props.hide}>
                <Modal.Header closeButton>
                    Kontaktní údaje
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        onSubmit={this.submitForm}
                    >
                        {(props) => (
                            <Form>
                                {/* {console.log(props)} */}
                                <Field as={TextField}
                                    fullWidth
                                    name="name"
                                    label="Jméno"
                                    helperText={<ErrorMessage name='name' />}
                                    error={props.errors.name && props.touched.name}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    helperText={<ErrorMessage name='email' />}
                                    error={props.errors.email && props.touched.email}
                                    required
                                /><br />
                                <Field as={TextField}
                                    fullWidth
                                    name="phoneNumber"
                                    label="telefon"
                                    helperText={<ErrorMessage name='phoneNumber' />}
                                    error={props.errors.phoneNumber && props.touched.phoneNumber}
                                    required
                                /><br />

                                {order.gift && (
                                    <>
                                        <h5 style={{ marginTop: 20 }}>Abychom Vám mohli zaslat dárek, vyplňte dodací adresu</h5>
                                        <Field as={TextField}
                                            fullWidth
                                            name="street"
                                            label="ulice"
                                            helperText={<ErrorMessage name='street' />}
                                            error={props.errors.street && props.touched.street}
                                            required
                                        /><br />
                                        <Field as={TextField}
                                            fullWidth
                                            name="city"
                                            label="město"
                                            helperText={<ErrorMessage name='city' />}
                                            error={props.errors.city && props.touched.city}
                                            required
                                        /><br />
                                        <Field as={TextField}
                                            fullWidth
                                            name="zip"
                                            label="PSČ"
                                            helperText={<ErrorMessage name='zip' />}
                                            error={props.errors.zip && props.touched.zip}
                                            required
                                        /><br />
                                        <p>Váš dárek zašleme na adresu prostřednictvím společnosti PPL.</p>
                                    </>
                                )}
                                <div style={{ marginTop: 20 }}>
                                    <Field as={Checkbox}
                                        fullWidth
                                        name="invoice"
                                        onClick={this.toggleInvoice}
                                    />
                                    <label htmlFor="invoice" >Chci zadat fakturační údaje</label>

                                    {this.state.invoice && (
                                        <>
                                            <Field as={TextField}
                                                fullWidth
                                                name="iCompany"
                                                label="Název firmy"
                                            /><br />
                                            <Field as={TextField}
                                                fullWidth
                                                name="iStreet"
                                                label="Ulice"
                                            /><br />
                                            <Field as={TextField}
                                                fullWidth
                                                name="iZip"
                                                label="PSČ"
                                            /><br />
                                            <Field as={TextField}
                                                fullWidth
                                                name="iCity"
                                                label="Město"
                                            /><br />
                                            <Field as={TextField}
                                                fullWidth
                                                name="iVATNumber"
                                                label="IČ"
                                            /><br />
                                            <Field as={TextField}
                                                fullWidth
                                                name="iDIC"
                                                label="DIČ"
                                            /><br />
                                        </>
                                    )}
                                </div>
                                <h5 style={{ marginTop: 20 }}>Pokud chcete poslat kávu konkrétní nemocnici, vyberte</h5>
                                <Field as={TextField}
                                    fullWidth
                                    name="hospital"
                                    select
                                    label="Nemocnice"
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option key={null} value={null}>
                                        Nechám to na vás
                                    </option>
                                    {this.state.hospitalsList.map((nem, index) => (
                                        <option key={index} value={nem._id}>
                                            {nem.name}
                                        </option>
                                    ))}
                                </Field><br />
                                <Field as={TextField}
                                    fullWidth
                                    style={{ marginTop: 10 }}
                                    name="message"
                                    label="Vzkaz nemocnici"
                                    variant="outlined"
                                    multiline rowsMax={4}
                                />
                                <Field as={Checkbox}
                                    fullWidth
                                    name="conditions"
                                    helperText={<ErrorMessage name='conditions' />}
                                    error={props.errors.conditions}
                                    required
                                />
                                <ErrorMessage name="conditions" component="div" className="invalid-feedback" />
                                <label htmlFor="conditions" >Souhlasím s obchodními podmínkami</label>

                                <div class="text-center" style={{ marginTop: 20 }}>
                                    {order.group === 'coffee' && (
                                        <Button color="primary" variant="contained" type="submit">
                                            Zaplatit {order.label} < FreeBreakfastIcon /> za {order.price},- Kč
                                        </Button>
                                    )}
                                    {order.group === 'bag' && (
                                        <Button color="primary" variant="contained" type="submit">
                                            Zaplatit {order.label} kávy pro doktora za {order.price},- Kč<br /> s dárkem {order.giftLabel} kávy {order.giftDetail.name} pro Vás
                                        </Button>
                                    )}
                                    {order.group === 'machine' && (
                                        <Button color="primary" variant="contained" type="submit">
                                            Zaplatit {order.label} za {order.price},- Kč<br /> s dárkem {order.giftLabel} kávy pro Vás
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik >
                    {/* <BtcPay show={this.state.btcPay} hide={this.toggleBtcPay} btcPayUrl={this.state.btcPayUrl} /> */}
                </Modal.Body>
            </Modal>
        )
    }
}

export default ModalOrderForm