import React, { Component } from 'react'

import { Container, Jumbotron } from 'react-bootstrap'
import Button from '@material-ui/core/Button'

import ModalDoctorsForm from './ModalDoctorsForm'
import ModalDoctorsResult from './ModalDoctorsResult'

// import { Formik } from 'formik'
// import * as yup from 'yup';

// const schema = yup.object().shape({
//     firstName: yup.string().required(),
//     lastName: yup.string().required(),
//     hospitalName: yup.string().required(),
//     vatNumber: yup.string().required(),
//     email: yup.string().required(),
// });


class Doctors extends Component {

    constructor(props) {
        super(props)

        this.state = {
            doctForm: false,
            registration: null
        }
    }

    handleShow = () => { this.setState({ doctForm: 1, registration: null }) }
    handleClose = () => { this.setState({ doctForm: false }) }
    next = (data) => {
        this.setState({ registration: data.passed })
        this.setState((prevState) => ({ doctForm: prevState.doctForm + 1 }))
    }

    render() {
        return (
            <>
                <Container id="doctors" style={{ backgroundColor: 'white' }}>
                    <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                        <h1 className="header">Registrace nového zdravotnického zařízení</h1>
                        <p>Do projektu se mohou zapojit lékaři i zdravotníci z první linie a přidat tak své pracoviště mezi partnerská zdravotnická zařízení.</p>
                        <h5>Jak se zapojit?</h5>
                        <p>Stačí se zaregistrovat - vyplnit na sebe kontaktní údaje a název nemocnice a oddělení.</p>
                        <p>My Vaši žádost předáme ke schválení, zkontaktujeme Vás a přidáme Vaše pracoviště na seznam nemocnic, kterým je káva dodávána.</p>
                        <p>Po schválení registrace Vás budeme kontaktovat, abychom se domluvili na instalaci kávovaru a dodání kávy.</p>
                        <p>S týmem servisních technikům provádíme v pravidelných intervalech údržbu a servis nainstalovaných kávovarů.</p>
                        <p>Současně budete přidáni na seznam mezi další zapojené nemocnice.</p>
                        <p>Pro zdravotníky je celý projekt zcela zdarma po celou dobu trvání a účastí se k ničemu nezavazujete.</p>
                        <p>Naším cílem je dopřát Vám možnost vychutnat si skvělou kávu a poskytnout Vám tolik potřebný kofein.</p>

                        <hr />
                        <div style={{ textAlign: 'center' }}><Button variant="contained" color="primary" onClick={this.handleShow}>Registrace nemocnice</Button></div>
                    </Jumbotron>
                </Container>

                <ModalDoctorsForm show={this.state.doctForm === 1} close={this.handleClose} next={this.next} />
                <ModalDoctorsResult show={this.state.doctForm === 2} close={this.handleClose} data={this.state.registration} />
            </>
        )
    }
}

export default Doctors
