import React from 'react'

import { Container, Jumbotron, Image } from 'react-bootstrap'

import { partners } from '../data/partners'

function Partners() {
    return (
        <>
            <Container id="partners" style={{ backgroundColor: 'white' }}>
                <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                    <h1 className="header">Partneři projektu</h1>
                    <p>Pojďte se připojit a stejně jako naši partneři pomáhejte s námi:</p>
                    <div className="d-flex justify-content-center align-baseline">
                        {partners.shops.map((item, index) => (
                            <div className="d-flex justify-content-center" style={{ float: 'left', width: 270 }} key={'patner-' + index.toString()}>
                                <a href={item.url} target='_blank' rel="noreferrer">
                                    <div className="d-flex justify-content-center" style={{ height: 90 }}>
                                        <Image src={process.env.PUBLIC_URL + item.logo} style={{ width: item.width }} />
                                    </div>
                                    <p className="d-flex justify-content-center">{item.name}</p>
                                </a>
                            </div>
                        ))}
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <hr />
                    <div className="justify-content-center align-baseline">
                        {partners.bussiness.map((item, index) => (
                            <div className="d-flex justify-content-center" style={{ float: 'left', margin: 40 }} key={'patner-' + index.toString()}>
                                <a href={item.url} target='_blank' rel="noreferrer">
                                    <div style={{ height: 80 }}>
                                        <Image src={process.env.PUBLIC_URL + item.logo} style={{ width: item.width }} />
                                    </div>
                                </a>
                            </div>
                        ))}
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center align-baseline">
                        {partners.technical.map((item, index) => (
                            <div className="d-flex justify-content-center" style={{ float: 'left', width: 270 }} key={'patner-' + index.toString()}>
                                <a href={item.url} target='_blank' rel="noreferrer">
                                    <div style={{ height: 90 }}>
                                        <Image src={process.env.PUBLIC_URL + item.logo} style={{ width: item.width }} />
                                    </div>
                                </a>
                            </div>
                        ))}

                        <div style={{ clear: 'both' }}></div>
                    </div>

                </Jumbotron>
            </Container>

        </>
    )
}

export default Partners
