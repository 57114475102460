import React, { Component } from 'react'
import { Modal, Image } from 'react-bootstrap'
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Icon } from '@iconify/react';

import Button from '@material-ui/core/Button'
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FreeBreakfastOutlinedIcon from '@material-ui/icons/FreeBreakfastOutlined';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import coffeeMachine from '@iconify-icons/si-glyph/coffee-machine';
import shoppingBag from '@iconify-icons/gg/shopping-bag';

import TabPanel from './TabPanel'

import { CoffeeSlider } from './CoffeeSlider'

import axios from 'axios'

import { products, giftProducts } from '../data/products'



const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

// const CoffeeButton = withStyles((theme) => ({
//     root: {
//         width: 120,
//         height: 40,
//         color: "#444",
//         margin: 5,
//         opacity: .4,
//         '&:hover': {
//             opacity: 1
//         }
//     }
// }))(Button);


export class ModalShop extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tab: 0,
            products: [],
            orderProduct: products[0],
            gift: 1,
            gifts: []
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    selectGift = (event, newAlignment) => {
        console.log('nastavuji ', newAlignment)
        newAlignment && (this.setState({ gift: newAlignment }))
    }

    next = () => {
        var result = this.state.orderProduct
        if (this.state.gift && this.state.orderProduct.group === "bag") {
            const gift = giftProducts.find(pr => pr.id === this.state.gift)
            // console.log(gift)
            // console.log(giftProducts)
            result.giftDetail = {
                id: gift.id,
                name: gift.name,
                pcs: this.state.orderProduct.gift
            }
        } else if (this.state.orderProduct.group === "machine") {
            result.giftDetail = {
                id: 9,
                name: 'kávovar ROOMA A10 černý',
                pcs: 1
            }
        }

        this.props.next({ orderProduct: result })
    }

    componentDidMount = () => {
        this.setState({ orderCoffee: 1 })
        axios.get('https://api.kavaprolekare.cz/products')
            .then(async response => {
                await this.setStateAsync({ products: response.data })
                await this.setStateAsync({ orderProduct: this.state.products[0] })
            })
            .catch(e => {
                // console.log(e);
            });
    }

    resetOrder = () => {
        this.setState({ orderCoffee: 1 })
    }

    setOrderCoffee = async (e, val) => {
        var value
        if (isNaN(e))
            value = val
        else
            value = e

        const p = this.state.products.filter(item => item.id === value)
        const product = p[0]
        await this.setStateAsync({ orderProduct: product })

        if (product.group === 'bag') {
            this.setGifts()
        }

        if (product.special) {
            this.handleChangeWeight(product.min)
        }
    }

    selectHospital = (val) => {
        this.setState({ hospital: val.target.value })
    }

    handleChange = (e, val) => {
        switch (val) {
            case 0:
                this.setOrderCoffee(1)
                break
            case 1:
                this.setOrderCoffee(6)
                break
            case 2:
                this.setOrderCoffee(11)
                break
            default:
                this.setOrderCoffee(null)
        }
        this.setState({ tab: val })
    }

    handleChangeWeight = (e) => {
        const value = isNaN(e) ? e.target.value : e
        var product = { ...this.state.orderProduct }
        const p = this.state.products.filter(val => val.id === product.id)
        const pr = p[0]

        product.size = value
        if (pr.gift) product.gift = value * pr.gift
        product.price = value * pr.price
        product.label = value + ' kg'
        if (pr.gift) product.giftLabel = value * pr.gift + ' kg'
        product.quantity = value * 1000

        this.setState({ orderProduct: product })
        this.setGifts()

    }

    setGifts = () => {
        var gifts = []
        this.state.orderProduct.items !== 'undefined' && this.state.orderProduct.items.length > 1 && (this.state.orderProduct.items[1].variants.forEach(item => {
            const pr = giftProducts.find(pr => pr.id === item)
            gifts.push(pr)
        }))

        this.state.orderProduct.items !== 'undefined'
            && this.state.orderProduct.items.length > 1
            && this.state.orderProduct.items[1].variants.includes(this.state.gift) === false
            && (this.setState({ gift: this.state.orderProduct.items[1].variants[0] }))


        this.setState({ gifts: gifts })
        // console.log(this.state.orderProduct)
        // console.log(this.state.gifts)
    }

    submitForm = (values) => {
        // console.log(values)
    }

    render() {
        return (
            <>
                <Modal show={true} onHide={this.props.hide}>
                    <Modal.Header closeButton>
                        {{
                            0: <Modal.Title>Šálek kávy pro doktora</Modal.Title>,
                            1: <Modal.Title>Zrnková káva pro doktora</Modal.Title>,
                            2: <Modal.Title>Kávovar pro doktora</Modal.Title>
                        }[this.state.tab]}
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Paper square>
                                <Tabs
                                    value={this.state.tab}
                                    onChange={this.handleChange}
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                >
                                    <Tab icon={<FreeBreakfastIcon />} aria-label="coffee" onClick={this.resetOrder} label="Šálek kávy" />
                                    <Tab icon={<Icon icon={shoppingBag} style={{ fontSize: '2em' }} onClick={this.resetOrder} />} label="Zrnková káva" />
                                    <Tab icon={<Icon icon={coffeeMachine} style={{ fontSize: '2em' }} onClick={this.resetOrder} />} label="Kávovar" />
                                </Tabs>
                            </Paper>
                            <TabPanel value={this.state.tab} index={0}>

                                <div style={{ display: 'flex' }}>
                                    {this.state.products.filter(item => item.group === 'coffee').map((product, index) => (
                                        <div style={{ width: '20%', float: 'left', textAlign: 'center', display: 'inline-block', alignSelf: 'flex-end' }} key={'cup' + index.toString()}>
                                            {product.id === this.state.orderProduct.id && (
                                                <>
                                                    <FreeBreakfastIcon key={product.id} style={{ fontSize: '3em' }} fontSize="large" />
                                                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{product.label}</p>
                                                </>
                                            )}
                                            {product.id !== this.state.orderProduct.id && (
                                                <>
                                                    <FreeBreakfastOutlinedIcon key={product.id} style={{ fontSize: '2em' }} />
                                                    <p style={{ textAlign: 'center' }}>{product.label}</p>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <CoffeeSlider
                                        color='secondary'
                                        // valueLabelDisplay="false"
                                        min={1} max={5}
                                        aria-label="coffee slider"
                                        marks={true}
                                        defaultValue={1}
                                        onChange={this.setOrderCoffee} />
                                </div>

                                <div className="text-center" style={{ marginTop: 20 }}>
                                    <Button color="primary" variant="contained" onClick={this.next}>
                                        Objednat {this.state.orderProduct.label} < FreeBreakfastIcon /> za {this.state.orderProduct.price},- Kč
                                    </Button>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tab} index={1}>
                                <h5 className="text-center" style={{ marginTop: 20 }}>
                                    <p>Vyberte balení kávy pro lékaře:</p>
                                </h5>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        {this.state.products.filter(item => item.group === 'bag').map((product, index) => (
                                            <div style={{ width: '20%', float: 'left', textAlign: 'center', display: 'inline-block', alignSelf: 'flex-end' }} key={'bag' + index.toString()}>
                                                {product.id === this.state.orderProduct.id && (
                                                    <>
                                                        <Icon variant='primary' icon={shoppingBag} style={{ fontSize: '3em' }} />
                                                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{product.label}</p>
                                                    </>
                                                )}
                                                {product.id !== this.state.orderProduct.id && (
                                                    <>
                                                        <Icon variant='primary' icon={shoppingBag} style={{ fontSize: '2em' }} />
                                                        <p style={{ textAlign: 'center' }}>{product.label}</p>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                        <CoffeeSlider
                                            color='secondary'
                                            min={6} max={10}
                                            aria-label="zrnkova kava"
                                            marks={true}
                                            defaultValue={1}
                                            onChange={this.setOrderCoffee} />
                                    </div>
                                    {this.state.orderProduct.special && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Input
                                                style={{ width: 80, fontSize: '2em' }}
                                                type="number"
                                                id="standard-adornment-weight"
                                                value={this.state.orderProduct.size}
                                                onChange={this.handleChangeWeight}
                                                endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                                aria-describedby="standard-weight-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                    'min': 6
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="text-center" style={{ marginTop: 20 }}>
                                    <h5>Zvolte si směs kávy jako dárek pro Vás:</h5>
                                </div>
                                <ToggleButtonGroup
                                    value={this.state.gift}
                                    exclusive
                                    onChange={this.selectGift}
                                    class="text-center"
                                >
                                    {this.state.gifts.map((pr, index) => {
                                        return (
                                            <ToggleButton value={pr.id} style={{ color: pr.color, textTransform: 'none' }} key={'gift-' + index.toString()}>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit"><span style={{ color: pr.color, fontWeight: 'bold' }}>{pr.nameTitle}</span><br />{pr.title}</Typography>
                                                            {pr.description}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>{pr.name}</div>
                                                </HtmlTooltip>
                                            </ToggleButton>
                                        )
                                    })}
                                </ToggleButtonGroup>


                                <div className="text-center" style={{ marginTop: 20 }}>
                                    <Button color="primary" variant="contained" onClick={this.next}>
                                        Objednat {this.state.orderProduct.label} kávy pro doktora za {this.state.orderProduct.price},- Kč<br /> s dárkem {this.state.orderProduct.giftLabel} kávy {giftProducts.find(val => val.id === this.state.gift).name} pro Vás
                                    </Button>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tab} index={2}>
                                <h5 className="text-center" style={{ marginTop: 20 }}>
                                    <p>Kávovar pro lékaře:</p>
                                </h5>
                                <div>
                                    <div style={{ display: 'flex', textAlign: 'center' }}>
                                        <div style={{ display: 'inline-block', width: '75%', textAlign: 'center', opacity: '1', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <img src={'../../coffeeMachines/A10.png'} alt="rooma-rm-a10" style={{ width: 'inherit' }} />
                                            {/* <img src={'../../coffeeMachines/rooma-rm-a10.jpg'} alt="rooma-rm-a10" style={{ width: 'inherit' }} /> */}
                                            <p>ROOMA RM-A10</p>
                                            <p>cena: {this.state.orderProduct.price},-</p>
                                        </div>

                                        {/* {this.state.orderProduct.id === 11 ? (
                                            <>
                                                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', opacity: '1' }}>
                                                    <img src={'../../coffeeMachines/rooma-rm-a10.jpg'} alt="rooma-rm-a10" style={{ width: 'inherit' }} />
                                                    <p>ROOMA RM-A10</p>
                                                    <p>cena: 17 999,-</p>
                                                </div>
                                                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', opacity: '.3' }}>
                                                    <img src={'../../coffeeMachines/rooma-rm-a10-black.jpg'} alt="rooma-rm-a10-black" style={{ width: 'inherit' }} />
                                                    <p>ROOMA RM-A10</p>
                                                    <p>cena: 17 999,-</p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', opacity: '.3' }}>
                                                    <img src={'../../coffeeMachines/rooma-rm-a10.jpg'} alt="rooma-rm-a10" style={{ width: 'inherit' }} />
                                                    <p>ROOMA RM-A10</p>
                                                    <p>cena: 17 999,-</p>
                                                </div>
                                                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', opacity: '1' }}>
                                                    <img src={'../../coffeeMachines/rooma-rm-a10-black.jpg'} alt="rooma-rm-a10-black" style={{ width: 'inherit' }} />
                                                    <p>ROOMA RM-A10</p>
                                                    <p>cena: 17 999,-</p>
                                                </div>
                                            </>
                                        )} */}

                                    </div>
                                    {/* <div style={{ paddingLeft: 70, paddingRight: 70 }}>
                                        <CoffeeSlider
                                            color='secondary'
                                            min={11} max={12}
                                            aria-label="machines slider"
                                            marks={true}
                                            defaultValue={11}
                                            onChange={this.setOrderCoffee} />
                                    </div> */}
                                </div>
                                <div>
                                    <div style={{ float: "left", width: '80%' }}>
                                        <h5 class="text-center">
                                            Pro Vás stejný {this.state.orderProduct.giftLabel} od nás.
                                        </h5>

                                    </div>
                                    <img style={{ float: "left", width: '60px' }} src={'../../coffeeMachines/rooma-rm-a10-black.jpg'} alt="rooma-rm-a10-black" />
                                </div>
                                <div class="text-center" style={{ marginTop: 20 }}>
                                    <Button color="primary" variant="contained" onClick={this.next}>
                                        Objednat {this.state.orderProduct.label} za {this.state.orderProduct.price},- Kč<br /> a {this.state.orderProduct.giftLabel} pro Vás
                                    </Button>
                                </div>
                            </TabPanel>
                        </div>
                        <hr />
                        <h3 className="text-center">Hlavní partneři projektu:</h3>
                        {/* <p class="text-center">si objednejte kafe domů u našich partnerů. My zašleme stejné množství zdravotníkovi.</p> */}

                        <div className="d-flex justify-content-center align-baseline">
                            <div className="d-flex justify-content-center" style={{ float: 'left', width: 300 }}>
                                <a href='https://eshop.dpcoffee.cz/' target='_blank' rel="noreferrer">
                                    <div style={{ height: 90 }}>
                                        <Image src={process.env.PUBLIC_URL + "/icon.png"} style={{ width: 90 }} roundedCircle />
                                    </div>
                                    <p>www.dpcoffe.cz</p>
                                </a>
                            </div>
                            <div className="d-flex justify-content-center" style={{ float: 'left', width: 300 }}>
                                <div>
                                    <a href='https://www.pilulka.cz/hledej?c%5B0%5D=7-363&q=kava' target='_blank' rel="noreferrer">
                                        <div className="d-flex justify-content-center" style={{ height: 90 }}>
                                            <Image src={process.env.PUBLIC_URL + "/pilulka-logo.svg"} style={{ width: 180 }} />
                                        </div>
                                        <p className="d-flex justify-content-center mt-auto">www.pilulka.cz</p>
                                    </a>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </Modal.Body>
                    )
                    <Modal.Footer>
                        <Button onClick={this.props.hide}>Zavřít</Button>
                    </Modal.Footer>
                </Modal >
            </>
        )
    }
}

export default ModalShop
