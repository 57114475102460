export const imageGallery = [
    {
        original: 'galery/CV10.jpg',
        thumbnail: 'galery/CV10.jpg'
    },
    {
        original: 'galery/Nehoda_spyta_001.jpg',
        thumbnail: 'galery/Nehoda_spyta_001.jpg'
    },
    {
        original: 'galery/Resuscitace_zachranka_HK_23716_71_retusZS.jpg',
        thumbnail: 'galery/Resuscitace_zachranka_HK_23716_71_retusZS.jpg'
    },
    {
        original: 'galery/Resuscitace_zachranka_HK_23716_290_retus_opr.jpg',
        thumbnail: 'galery/Resuscitace_zachranka_HK_23716_290_retus_opr.jpg'
    },
    {
        original: 'galery/Zachranka_HK_17617_207_retus.jpg',
        thumbnail: 'galery/Zachranka_HK_17617_207_retus.jpg'
    },
    {
        original: 'galery/Zachranka_HK_17617_592_loga.jpg',
        thumbnail: 'galery/Zachranka_HK_17617_592_loga.jpg'
    },
    {
        original: 'galery/Zachranka_HK_B23716_298.jpg',
        thumbnail: 'galery/Zachranka_HK_B23716_298.jpg'
    },
]