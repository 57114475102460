import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

export const CoffeeSlider = withStyles({
    root: {
        height: 8,
    },
    thumb: {
        height: 32,
        width: 32,
        border: '4px solid currentColor',
        marginTop: -12,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 5px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
    mark: {
        height: 18,
        width: 5,
        marginTop: -10,
        backgroundColor: '#B9AB97'
    },
    markActive: {
        opacity: 1,
    },
})(Slider);