//https://www.npmjs.com/package/react-image-gallery

import React, { Component } from 'react'
import '../App.css';
import { Container } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';

// import { galery } from '../data/galery'
import { imageGallery } from '../data/image-gallery.js'

class Gallery extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hospitalListShow: false,
            coffeeQuantity: null,
            hospitalsCount: 0,
            hospitalsList: []
        }
    }

    render() {
        return (
            <Container id="galery" style={{ backgroundColor: 'white', paddingLeft: 0, paddingRight: 0 }}>
                <h1 className="header">Galerie</h1>
                <ImageGallery items={imageGallery} />
            </Container>

        )
    }
}

export default Gallery
