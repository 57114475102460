import React, { Component } from 'react'

import { products } from '../data/products'

import { ModalShop } from './ModalShop'
import { ModalOrderForm } from './ModalOrderForm'
import { ModalPaymentResult } from './ModalPaymentResult'

export class OrderPopUp extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 0,
            orderProduct: products[0],
            payment: {}
        }
    }

    componentDidMount = () => {
        this.setState({ step: 0, orderCoffee: 1 })

    }

    next = (states) => {
        this.setState(states)
        const step = this.state.step + 1
        this.setState({ step: step })
    }

    prev = (states) => {
        this.setState(states)
        var step = this.state.step - 1
        if (step < 0) step = 0
        this.setState({ step: step })
    }

    switchPanel = (panel) => {
        this.setState({ step: panel })
    }

    render() {
        return (
            <>
                {this.state.step === 0 && this.props.show && (
                    <ModalShop
                        next={this.next}
                        hide={this.props.hide} />
                )
                }
                {
                    this.state.step === 1 && this.props.show && (
                        <ModalOrderForm
                            orderProduct={this.state.orderProduct}
                            prev={this.prev}
                            next={this.next}
                            hide={this.props.hide} />
                    )
                }
                {
                    this.state.step === 2 && this.props.show && (
                        <ModalPaymentResult
                            payment={this.state.payment}
                            hide={this.props.hide} />
                    )
                }
            </>
        )
    }
}

export default OrderPopUp



