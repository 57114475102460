import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { Modal } from 'react-bootstrap'
import axios from 'axios'

import { paymentResults } from '../data/constants'

export class ModalPaymentResult extends Component {
    constructor(props) {
        super(props)

        this.state = {
            payment: {},
            state: false,
            paymentId: null,
            paymentResult: null,
            order: false,
            show: true
        }
    }

    componentDidMount() {
        this.setState({ payment: this.props.payment })
        this.checkPayment(this.props.payment.id, this.props.payment.gateway)
        this.setState({ show: true })
    }

    checkPayment = (id, gateway = "") => {
        axios.get('https://api.kavaprolekare.cz/goPayNotify', { params: { id: id, gateway: gateway } })
            .then(r => {
                axios.get('https://api.kavaprolekare.cz/order', { params: { 'orderHeader.payment.paymentID': id } })
                    .then(async response => {
                        await this.setState({ order: response.data })
                        this.setState({ state: r.data.state })
                        console.log(this.state.order)
                    })
            })
    }

    payOrder = () => {
        window._gopay.checkout({ gatewayUrl: this.state.order.orderHeader.payment.url, inline: true }, (checkoutResult) => {
            this.checkPayment(checkoutResult.id)
        })
    }

    fun() {
        this.props.url && this.props.history.push("/");
        this.toggleModal()
    }

    toggleModal = () => this.state.show ? this.setState({ show: false }) : this.setState({ show: true })

    render() {
        return (
            <Modal show={this.state.show} onHide={this.fun.bind(this)} >
                <Modal.Header closeButton>
                    {paymentResults[this.state.state]}
                </Modal.Header>
                <Modal.Body>
                    {this.state.state === "PAID" && (
                        <>
                            <h4><b>Děkujeme za Vaší pomoc lékařům.</b></h4>
                            <p>Na Vámi uvedenou emailovou adresu jsme odeslali potvrzení objednávky.</p>
                            <hr />
                        </>
                    )}
                    {this.state.order && (
                        <>
                            <p>Objednávka č. {this.state.order.orderHeader.number}</p>
                            <h4>Položky:</h4>
                            {this.state.order.orderDetailType.orderItems.map(it => (
                                <p>{it.quantity} x {it.text}</p>
                            ))}
                        </>
                    )}
                    {this.state.state === "CREATED" && (
                        <Button color="primary" variant="contained" type="submit" onClick={this.payOrder}>Zaplatit</Button>
                    )}
                    {this.state.paymentResult}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.url && (
                        <Button variant="secondary" onClick={this.fun.bind(this)}>Zpět do obchodu</Button>
                    )}
                    {!this.props.url && (
                        <Button variant="secondary" onClick={this.fun.bind(this)}>Zavřít</Button>
                    )}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withRouter(ModalPaymentResult)