import React, { Component } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import Button from '@material-ui/core/Button'
import { OrderPopUp } from './OrderPopUp'
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { SocialIcon } from 'react-social-icons';

class Navigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            orderShow: false
        }
    }
    toggleOrderCoffee = () => this.state.orderShow ? this.setState({ orderShow: false }) : this.setState({ orderShow: true })

    smoothScroll = (e) => {
        var objects = e.target.attributes
        for (var i = 0; i < objects.length; i++) {
            if (objects[i].name === 'href') {
                if (objects[i].nodeValue[0] === '#') {
                    e.preventDefault();
                    document.getElementById(objects[i].nodeValue.substring(1)).scrollIntoView({ behavior: 'smooth' })
                    break
                }
            }
        }
    }

    render() {
        return (
            <>
                <Navbar collapseOnSelect fixed='top' expand="xl" variant="light" className="t-0 p-0" >
                    <Container style={{ backgroundColor: 'white' }} >
                        <Navbar.Brand href="#home" onClick={this.smoothScroll} className="d-none d-sm-inline-block">
                            <img

                                alt=""
                                src={process.env.PUBLIC_URL + "/logo_kriz.png"}
                                height="40"
                                className="align-top"
                                style={{ marginRight: 20 }}
                            />{'  '}káva pro lékaře
                        </Navbar.Brand>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'red' }}
                                onClick={this.toggleOrderCoffee}>
                                {<FavoriteOutlinedIcon style={{ marginRight: 10 }} />}
                                Chci pomoct {
                                    <>
                                        <FreeBreakfastIcon style={{ marginLeft: 10 }} />
                                        <ArrowForwardOutlinedIcon />
                                        <LocalHospitalOutlinedIcon />
                                    </>
                                }
                            </Button>
                        </div >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 'initial' }}>
                            <Nav>
                                <Nav.Link href='#project' onClick={this.smoothScroll} className='text-sm-center'>Projekt</Nav.Link>
                                <Nav.Link href='#aboutUs' onClick={this.smoothScroll} className='text-sm-center'>O nás</Nav.Link>
                                <Nav.Link href='#galery' onClick={this.smoothScroll} className='text-sm-center'>Galerie</Nav.Link>
                                <Nav.Link href='#partners' onClick={this.smoothScroll} className='text-sm-center'>Partneři</Nav.Link>
                                <Nav.Link href='#questions' onClick={this.smoothScroll} className='text-sm-center'>Pomáhejte s námi</Nav.Link>
                                <Nav.Link href='#doctors' onClick={this.smoothScroll} className='text-sm-center'>Zdravotníci</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <SocialIcon url="https://www.facebook.com/dpcoffeecz/" bgColor="#1877f2" style={{ height: 40, width: 40 }} />
                    </Container>

                </Navbar >
                {this.state.orderShow && (
                    <OrderPopUp show={this.state.orderShow} hide={this.toggleOrderCoffee} />
                )}
            </>
        )
    }
}

export default Navigation


