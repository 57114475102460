import React, { Component } from 'react'
import { Container, Jumbotron, Row, Col } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import { SocialIcon } from 'react-social-icons';
import { TermsConditionsPopUp } from './TermsConditionsPopUp'

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';

class Bottom extends Component {

    constructor(props) {
        super(props)

        this.state = {
            terms: false
        }
    }


    toggleTerms = () => this.state.terms ? this.setState({ terms: false }) : this.setState({ terms: true })
    render() {
        return (
            <>
                <Container id="aboutUs" style={{ backgroundColor: 'white' }}>
                    <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                        <h1 className="header">Kontakty</h1>
                        <p><b>Coffee Blenders s.r.o.</b></p>
                        <Container>
                            <Row>
                                <Col>
                                    <p>Urbanická 18</p>
                                    <p>190 17 Praha 9 – Vinoř</p>
                                    <p>IČ: 27206823</p>
                                    <p>DIČ: CZ27206823</p>
                                    <p>Spisová značka: C 104444 vedená u Městského soudu v Praze</p>

                                </Col>
                                <Col>
                                    <p><LanguageIcon /> <a href='https://www.dpcoffee.cz'>www.dpcoffee.cz</a></p>
                                    <p><EmailIcon /> obchod@dpcoffee.cz</p>
                                    <p><EmailIcon /> servis@dpcoffee.cz</p>
                                    <p><PhoneIcon /> +420 602 630 830</p>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <SocialIcon url="https://www.facebook.com/dpcoffeecz/" bgColor="#1877f2" style={{ height: 40, width: 40 }} /><br />
                                    <SocialIcon url="https://www.instagram.com/dp_coffee_cz/" style={{ height: 40, width: 40, marginTop: '10px' }} />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        <div className="text-center" style={{ marginTop: 24 }}><Button variant="contained" color="primary" onClick={this.toggleTerms}>Obchodní podmínky</Button></div>
                    </Jumbotron>
                </Container>
                <TermsConditionsPopUp show={this.state.terms} hide={this.toggleTerms} />
            </>
        )
    }
}

export default Bottom
