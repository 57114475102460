export const carouselGallery = [
    {
        img: '/carousel-gallery/01.jpg',
        alt: 'doktori',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/02.jpg',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/kava02.jpg',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/03.jpg',
        alt: 'kava',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/04.jpg',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/kava03.png',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/05.jpg',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
    {
        img: '/carousel-gallery/kava01.jpg',
        alt: 'covid',
        caption: 'Kava pro lekare'
    },
]