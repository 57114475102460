export const orderStates = {
    CREATED: "Přijata",
    PROCESSING: "Připravuje se",
    WAITINGFORSHIPMENT: "Připravena k odeslání",
    SHIPPED: "V přepravě",
    DELIVERED: "Doručena"
}

export const paymentResults = {
    CREATED: "Platba byla předčasně ukončena. Zkuste zaplatit znovu.",
    CANCELED: "Platba byla zrušena.",
    PAID: "Platba proběhla úspěšně.",
    PAYMENT_METHOD_CHOSEN: "Platba proběhla. Po připsání na náš účet objednávku vyřídíme."
}