import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'

export class DoctorsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: true
        }
    }

    handleClose = () => { this.props.close() }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Registrace nemocnice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Děkujeme za Vaší registraci.</p>
                    <p>Brzy Vás budeme kontaktovat.</p>
                    <p>{this.props.data}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DoctorsForm
