import React, { Component } from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Icon } from '@iconify/react';

import coffeeMachine from '@iconify-icons/si-glyph/coffee-machine';
import shoppingBag from '@iconify-icons/gg/shopping-bag';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';

import { OrderPopUp } from './OrderPopUp'

class HelpWithUs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orderShow: false
        }
    }

    toggleOrderCoffee = () => this.state.orderShow ? this.setState({ orderShow: false }) : this.setState({ orderShow: true })

    render() {
        return (
            <>
                <Container id="questions" style={{ backgroundColor: 'white' }}>
                    <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>

                        <h1 className="header">Jak můžete pomoci s námi?</h1>
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="1" src="/static/images/avatar/1.jpg" ><FreeBreakfastIcon style={{ fontSize: '1.5em' }} /></Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Pozvěte lékaře virtuálně na šálek kávy"
                                    secondary={
                                        <>
                                            Zakupte doktorům 1 – 10 šálků kávy. Každý týden zašleme v hodnotě zakoupených šálků adekvátní množství balení zrnkové kávy.<br />
                                            Kávu můžete poslat konkrétní nemocnici, kterou si vyberete při objednání ze seznamu zapojených nemocnic.<br />
                                            Doktorům a sestrám můžete zanechat vzkaz a my jim Vaším jménem poděkujeme a předáme vzkaz při dodání kávy.
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="2"><Icon variant='primary' icon={shoppingBag} style={{ fontSize: '1.25em' }} /></Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Zakupte lékařům na vybrané oddělení zrnkovou kávu"
                                    secondary={
                                        <>
                                            Zvolte množství kávy, které chcete zakoupit lékařům (1 kg - 5 kg nebo vlastní množství v kg) a vyberte si ze seznamu nemocnici, které máme kávu zaslat.<br />
                                            Od nás jako poděkování získáte dárek v podobě naší výběrové kávy.<br />
                                            Při objednání můžete vybrat ze seznamu registrovaných zdravotnických zařízení a zakoupit tak kávu konkrétní nemocnici.
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="3"><Icon icon={coffeeMachine} style={{ fontSize: '1em' }} onClick={this.resetOrder} /></Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Zakupte kávovar do nového zdravotnického zařízení a získejte stejný od nás"
                                    secondary={
                                        <>
                                            Pokud byste chtěli pomoci rozšířit seznam nemocnic, ve kterých si mohou lékaři a sestry vychutnat chvíle klidu s šálkem výborné kávy, a poděkovat jim tak za jejich nasazení v nelehké době, můžete i vy u nás zakoupit do nemocnice kávovar Rooma A10 za zvýhodněnou cenu 15.900,- Kč.<br />
                                            Kávovar dodáme do nemocnice Vaším jménem a jako poděkování od nás získáte ten samý kávovar v černé barvě.<br />
                                            Ze seznamu můžete zvolit zdravotnické zařízení, které splňuje podmínky partnerských míst a čeká na instalaci kávovaru.<br />
                                            Pomůžete nám tak zvýšit počet nemocnic, kde společně pomáháme.
                                        </>
                                    }
                                />
                            </ListItem>
                        </List>
                        <div className="text-center" style={{ marginTop: 24 }}><Button variant="contained" color="primary" onClick={this.toggleOrderCoffee}>Objednat kávu pro lékaře</Button></div>
                    </Jumbotron>
                </Container>
                <OrderPopUp show={this.state.orderShow} hide={this.toggleOrderCoffee} />
            </>
        )
    }
}

export default HelpWithUs
