import React, { Component } from 'react'
import '../App.css';
import { Jumbotron, Container, Modal, ListGroup } from 'react-bootstrap';

import Button from '@material-ui/core/Button'

import axios from 'axios'

import io from 'socket.io-client'

import Carousel from './Carousel'

const COFFEEGRAMS = 7

class MainPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hospitalListShow: false,
            coffeeQuantity: null,
            machinesCount: null,
            hospitalsCount: 0,
            hospitalsList: []
        }
    }

    componentDidMount = () => {
        const socket = io.connect('https://api.kavaprolekare.cz')
        //const socket = io.connect('wss://localhost:3443')

        socket.on('message', message => {
            // console.log(message)
            message.coffeeQuantity && this.setState({ coffeeQuantity: message.coffeeQuantity })
            message.hospitalsCount && this.setState({ hospitalsCount: message.hospitalsCount })
            message.machinesCount && this.setState({ machinesCount: message.machinesCount })
        })
    }

    handleHospitalShow = () => {
        axios.get('https://api.kavaprolekare.cz/hospitals')
            .then(response => {
                this.setState({ hospitalsList: response.data, hospitalListShow: true })
                // console.log(this.state.hospitalsList)
            })
            .catch(e => {
                // console.log(e);
            });
    }
    handleHospitalClose = () => { this.setState({ hospitalListShow: false }) }

    render() {
        return (
            <>
                <Container id="home" style={{ backgroundColor: 'white', paddingLeft: 0, paddingRight: 0, paddingTop: 72 }}>
                    <Carousel></Carousel>
                    <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                        {/* <form method="POST" onsubmit="onBTCPayFormSubmit(event);return false" action="https://btcpay.rvtg.cz/api/v1/invoices" class="btcpay-form btcpay-form--block">
                            <input type="hidden" name="storeId" value="4tERbuiC1QCprEVnnCpaXbi8V3bxy5y3wpbofD9uWPHW" />
                            <input type="hidden" name="jsonResponse" value="true" />
                            <input type="hidden" name="price" value="2" />
                            <input type="hidden" name="currency" value="EUR" />
                            <input type="image" class="submit" name="submit" src="https://btcpay.rvtg.cz/img/paybutton/pay.svg" style={{ width: "146px" }} alt="Pay with BtcPay, Self-Hosted Bitcoin Payment Processor" />
                        </form> */}

                        <h4 className="text-center">spolu s Vámi jsme již poslali <b>{Math.ceil(this.state.machinesCount)} kávovarů</b> a</h4>

                        <h1 style={{ fontSize: '8vmin' }} className="header">{Math.ceil(this.state.coffeeQuantity / COFFEEGRAMS)} šálků kávy</h1>
                        {/* <h2 className="header">a {Math.ceil(this.state.machinesCount)} kávovarů</h2> */}
                        {/* <h4 class="text-center">( {this.state.coffeeQuantity} kg kávy )</h4> */}
                        <div className="text-center" style={{ marginTop: 24 }}><Button color='primary' variant="contained" onClick={this.handleHospitalShow}>{this.state.hospitalsCount} nemocnicím</Button></div>
                    </Jumbotron>
                </Container>

                <Modal show={this.state.hospitalListShow} onHide={this.handleHospitalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Seznam nemocnic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup variant="flush">
                            {this.state.hospitalsList.map((item) =>
                                <ListGroup.Item>
                                    <h3>{item.name}</h3>
                                    <p3>{item.machines}x kávovar, {Math.ceil(item.delivered / COFFEEGRAMS)} šálků kávy</p3>
                                </ListGroup.Item>)}
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHospitalClose}>
                            Zavřít
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default MainPage
