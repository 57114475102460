import React from 'react'

import { Container, Jumbotron } from 'react-bootstrap'

function AboutUs() {
    return (
        <>
            <Container id="aboutUs" style={{ backgroundColor: 'white' }}>
                <Jumbotron style={{ backgroundColor: 'white', color: '#444' }}>
                    <h1 className="header">O nás</h1>
                    <p>Společnost <b>Coffee Blenders s.r.o.</b> se na trhu s kávou pohybuje již od roku 2006.</p>

                    <p>Kávu milujeme, a proto jsme vytvořili vlastní značku <b>dp coffee</b> a mícháme směsi, do kterých dáváme svoje srdce, rozum i všechny zkušenosti, které jsme postupně nasbírali.</p>
                    <p>Našimi partnery jsou převážně hotelové sítě vyšší kategorie. Naše káva však dělá denně radost i mnoha milovníkům kávy doma či v kancelářích.</p>
                    <p>Patříme ke středním producentům s výrobou cca 50 tun kávy ročně.</p>
                    <p>Naše práce nám dělá radost a tak i my chceme rozdávat radost druhým a pomoci těm, kteří pomáhají.</p>
                </Jumbotron>
            </Container>

        </>
    )
}

export default AboutUs
