export const products = [
    { id: 1, group: 'coffeee', size: 1, price: 50, label: '1x', giftLabel: '', quantity: 10 },
    // { id: 2, group: 'coffeee', size: 2, price: 99, label: '2x', giftLabel: '', quantity: 20 },
    // { id: 3, group: 'coffeee', size: 3, price: 150, label: '3x', giftLabel: '', quantity: 30 },
    // { id: 4, group: 'coffeee', size: 5, price: 250, label: '5x', giftLabel: '', quantity: 50 },
    // { id: 5, group: 'coffeee', size: 10, price: 500, label: '10x', giftLabel: '', quantity: 100 },
    // { id: 6, group: 'bag', size: 1000, gift: 200, price: 690, label: '1kg', giftLabel: '200g', quantity: 1000 },
    // { id: 7, group: 'bag', size: 2000, gift: 1000, price: 1380, label: '2kg', giftLabel: '1kg', quantity: 2000 },
    // { id: 8, group: 'bag', size: 3000, gift: 3000, price: 2070, label: '3kg', giftLabel: '3kg', quantity: 3000 },
    // { id: 9, group: 'bag', size: 5000, gift: 5000, price: 4450, label: '5kg', giftLabel: '5kg', quantity: 5000 },
    // { id: 10, group: 'bag', size: null, gift: 1, price: 690, label: 'vlastní', giftLabel: 'speciální', special: true, min: 6 },
    // { id: 11, group: 'machine', name: 'ROOMA A10 stříbrná', size: 1, gift: 1, price: 15900, label: 'kávovar', giftLabel: 'kávovar ROOMA A10 stříbrný' },
    // { id: 12, group: 'machine', name: 'ROOMA A10 černá', size: 1, gift: 1, price: 15900, label: 'kávovar', giftLabel: 'kávovar ROOMA A10 černý' },
]

export const giftProducts = [
    { id: 1, name: "No.3", color: "#f69d01", nameTitle: "dp COFFEE No.3", title: "Silné tělo s jasnou hlavou", description: "Výběrovou směs tvoří jihoamerická arabika doplněná o pranou a nepranou robustu. Tato káva má silné tělo, jasnou čokoládovou chuť bez výrazných kyselých akcentů. Ideální rovnováha sladkosti a hořkosti probouzí jiskru ducha nejen při práci." },
    { id: 2, name: "PINK", color: "#ff69b4", nameTitle: "dp PINK COFFEE", title: "Káva, která rozumí ženám", description: "Poznejte kávu PINK, která si díky unikátnímu pražení uchovává co nejvíce antioxidantů pro zdraví a krásu. Speciálně vybraná směs chutná po sladkém karamelu, šťavnatých višních a smyslném sweet chilli. Skvěle si rozumí s mlékem, stejně jako vy s vaší nejlepší kamarádkou." },
    { id: 3, name: "red", color: "#cc0f0b", nameTitle: "dp CUSTOMIZED red", title: "Pro váš přesvědčivý výkon", description: "Originální směs vyvinutá ve spolupráci s vrcholovými sportovci a profesionály, kteří podávájí denně špičkové výkony a chtějí, aby jim v tom káva pomáhala. CUSTOMIZED red má vyšší obsah kofeinu, antioxidantů a výraznější ušlechtilou hořkost. Zažijte den 'nakopnutý' CUSTOMIZED red a buďte tvůrcem svého života." },
    { id: 4, name: "No.3", color: "#f69d01", nameTitle: "dp COFFEE No.3", title: "Silné tělo s jasnou hlavou", description: "Výběrovou směs tvoří jihoamerická arabika doplněná o pranou a nepranou robustu. Tato káva má silné tělo, jasnou čokoládovou chuť bez výrazných kyselých akcentů. Ideální rovnováha sladkosti a hořkosti probouzí jiskru ducha nejen při práci." },
    { id: 5, name: "PINK", color: "#ff69b4", nameTitle: "dp PINK COFFEE", title: "Káva, která rozumí ženám", description: "Poznejte kávu PINK, která si díky unikátnímu pražení uchovává co nejvíce antioxidantů pro zdraví a krásu. Speciálně vybraná směs chutná po sladkém karamelu, šťavnatých višních a smyslném sweet chilli. Skvěle si rozumí s mlékem, stejně jako vy s vaší nejlepší kamarádkou." },
    { id: 6, name: "red", color: "#cc0f0b", nameTitle: "dp CUSTOMIZED red", title: "Pro váš přesvědčivý výkon", description: "Originální směs vyvinutá ve spolupráci s vrcholovými sportovci a profesionály, kteří podávájí denně špičkové výkony a chtějí, aby jim v tom káva pomáhala. CUSTOMIZED red má vyšší obsah kofeinu, antioxidantů a výraznější ušlechtilou hořkost. Zažijte den 'nakopnutý' CUSTOMIZED red a buďte tvůrcem svého života." }
]